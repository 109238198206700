import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Tabs } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { TAB_ADVERTISE } from '../../constants';
import ButtonImage from './../../components/button-img';
import {  useAppSelector } from '../../stores';

import TabInfo from './TabInfo';
import TabPlace from './TabPlace';
import { AdvertiseWrapper } from './styled';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Advertise = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  // const [tabActive, setTabActive] = useState(TAB_ADVERTISE.INFO);
  const { isLoading } = useAppSelector(
    (state) => state.advertise
  );
  const items = [
    {
      key: TAB_ADVERTISE.INFO,
      label: <>Info ads</>,
    },
    {
      key: TAB_ADVERTISE.PLACE,
      label: <>My ads placed</>,
    },
  ];

  useEffect(() => {
    if(!searchParams.get('tab')) {
      setSearchParams({
        tab: TAB_ADVERTISE.INFO
      })
    }
  }, []);
  const onChangeTab = (key) => {
    setSearchParams({
      tab: key
    })
  };
  const tabActive = useMemo(() => searchParams.get('tab'), [searchParams.get('tab')])

  return (
    <AdvertiseWrapper>
      {isLoading && (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
              }}
              spin
            />
          }
        />
      )}

      <div className="dp-flex justify-between title">
        <h2>Advertise</h2>

        <ButtonImage
          onClick={() => {
            navigate('/advertise/new-advertise');
          }}
          type="action"
          height={50}
          textTransform="unset"
          fontWeight={300}
          fontSize={14}
        >
          New Advertisement
        </ButtonImage>
      </div>

      <div>
        <Tabs items={items} activeKey={tabActive} onChange={onChangeTab} className="advertise-tab" />
        {tabActive === TAB_ADVERTISE.INFO && (
          <TabInfo />
        )}
        {tabActive === TAB_ADVERTISE.PLACE && (
          <TabPlace />
        )}
      </div>
    </AdvertiseWrapper>
  );
};

export default Advertise;
