import { LoadingOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { STATUS_ADS, STATUS_NETWORK } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../stores';
import {
  createAdvertiseAction,
  listBuildingAction,
  tableAdsBookingAction,
  tablePricingPlanAction,
} from '../../../stores/screens/advertise/advertise.action';
import { listRequestManagementAction } from '../../../stores/screens/requestManagement/management.action';
import { Images } from '../../../theme';
import AdvertiseTable from '../AdvertiseTable';
import FooterAdvertiseTable from '../AdvertiseTable/FooterAdvertiseTable';
import AdvertiseFilter from './components/Filter';
import ButtonImage from './../../../components/button-img';
import actions from './../../../stores/screens/advertise/advertise.reducer';
import CompletedModal from './../../Management/CompletedModal';
import { NewAdvertiseWrapper } from './styled';
import { getUserInfoAction } from '../../../stores/screens/auth/auth.action';
import { useWeb3Auth } from '../../../contexts/web3auth';
import Storage from '../../../utils/storage';
import { Building } from '../../AdvertiseDetail/components/table/styled';

const NewAdvertise = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [paginate, setPaginate] = useState({});
  const [totalTime, setTotalTime] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [timeFrame, setTimeFrame] = useState([]);
  const [validationError, setValidationError] = useState({});
  const [resetSelectedItem, setResetSelectedItem] = useState(false);
  const [itemsSelectedFooter, setItemsSelectedFooter] = useState([]);
  const { resetTableAdvertise } = actions;

  const { tableAdvertise, tablePricing, feePercent,discountPercent, daySelected, paramsSearch } = useAppSelector(
    (state) => state.advertise
  );

  const { listRequestManagement } = useAppSelector(
    (state) => state.requestManagement
  );

  const { setUserInfo } = useWeb3Auth();

  const validateObject = (obj) => {
    const requiredFields = [
      'buildingId',
      'type',
      'category',
      'startDate',
      'endDate',
    ];
    const missingFields = [];

    requiredFields.forEach((field) => {
      if (
        !(field in obj) ||
        obj[field] === '' ||
        obj[field] === undefined ||
        obj[field] === null
      ) {
        missingFields[field] = true;
      }
    });
    setValidationError(missingFields);

    return Object.keys(missingFields).length === 0;
  };

  const onSearch = async () => {
    if (!validateObject(paginate)) return;
    setResetSelectedItem(true);
    setLoading(true);
    await dispatch(tablePricingPlanAction(paginate));
    const res = await dispatch(tableAdsBookingAction(paginate));
    setLoading(false);
    if (res?.meta?.requestStatus === STATUS_NETWORK.REJECTED) {
      message.open({
        type: 'error',
        content: res?.payload?.message,
      });
      return;
    }

    setResetSelectedItem(false);
  };

  const setAds = async () => {
    if (!tableAdvertise?.length) return;
    try {
      if (!validateObject(paginate)) return;
      const payloads = {
        ads_frames: timeFrame,
        advertisement_id: paramsSearch?.advertisementId,
        end_time: paramsSearch?.endDate,
        start_time: paramsSearch?.startDate,
        fee_percent: feePercent,
        discount_percent: discountPercent
      };

      setLoading(true);
      const res = await dispatch(createAdvertiseAction(payloads));

      setLoading(false);
      if (res?.meta?.requestStatus === STATUS_NETWORK.REJECTED) {
        message.open({
          type: 'error',
          content: res?.payload?.message === 'INSUFFICIENT_FUNDS' ? 'Insufficient balance for payment' : res?.payload?.message,
        });
        return;
      }
      setModalSuccess(true);
      setResetSelectedItem(true);
    } catch (error) { }
  };

  const fetchTable = async () => {
    setLoading(true);
    setValidationError({});
    setPaginate({});
    setTotalTime(0);
    setTotalAmount(0);
    await Promise.all([
      dispatch(resetTableAdvertise()),
      dispatch(tablePricingPlanAction({})),
      dispatch(listBuildingAction({})),
      dispatch(listRequestManagementAction({ status: STATUS_ADS.APPROVED })),
    ]);
    setLoading(false);
  };

  const renderTimeFrame = (item) => {
    const newArr = [];
    const frameIds = [...new Set(item?.map((i) => i.id))];

    frameIds.forEach((frameId) => {
      const times = item
        .filter((i) => i.id === frameId)
        .map((i) => ({ hour: i.hour, price: i.price }));
        const uniqueTimes = Array.from(new Set(times.map(a => JSON.stringify(a)))).map(str => JSON.parse(str));
      newArr.push({ frame_id: frameId, plan_prices: uniqueTimes, frame: itemsSelectedFooter?.find((item) => item?.id === frameId)?.category, building: itemsSelectedFooter?.find((item) => item?.id === frameId)?.building });
    });
    setTimeFrame(newArr);
  };

  useEffect(() => {
    fetchTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBalance = async () => {
    const { payload } = await dispatch(
      getUserInfoAction({ accessToken: Storage.get('ACCESS_TOKEN') })
    );

    if (payload?.status) {
      setUserInfo(payload?.data);
    }
  };

  return (
    <NewAdvertiseWrapper>
      {loading && (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
              }}
              spin
            />
          }
        />
      )}
      <div className="breadcrumb">
        <span
          onClick={() => {
            navigate('/advertise');
          }}
          className="click"
        >
          Advertise /
        </span>
        <span className="active"> New Advertisement</span>
      </div>
      <div className="group-filter">
        <AdvertiseFilter
          setPaginate={setPaginate}
          paginate={paginate}
          onSearch={onSearch}
          advertiseOption={listRequestManagement?.items}
          error={validationError}
          resetSelectedItem={resetSelectedItem}
        />
      </div>

      <div className="custom-frame">
        <div className="position-frame">
          <img src={Images.TABLE_FRAME_4} alt="" className="frame" />
          <img
            src={Images.LIGHT_DOT}
            alt=""
            className="light-dot light-dot-1"
          />
          <img
            src={Images.LIGHT_DOT}
            alt=""
            className="light-dot light-dot-2"
          />
          <img
            src={Images.LIGHT_DOT}
            alt=""
            className="light-dot light-dot-3"
          />
        </div>

        <div className="custom-frame-div">
          <AdvertiseTable
            dataTable={tableAdvertise}
            tablePricing={tablePricing}
            isCreate={true}
            totalAmount={totalAmount}
            totalTime={totalTime}
            setTotalAmount={setTotalAmount}
            setTotalTime={setTotalTime}
            renderTimeFrame={(item) => renderTimeFrame(item)}
            resetSelectedItem={resetSelectedItem}
            setItemsSelectedFooter={setItemsSelectedFooter}
          />

          <FooterAdvertiseTable
            isCreate={true}
            totalAmount={totalAmount}
            totalTime={totalTime}
            daySelected={daySelected}
            feePercent={feePercent}
            paramsSearch={paramsSearch}
            timeFrame={timeFrame}
            discountPercent={discountPercent}
          />
        </div>
      </div>

      <div className="group-btn">
        <ButtonImage
          height={50}
          type="cancel"
          textTransform="unset"
          fontWeight={300}
          fontSize={14}
          onClick={() => {
            navigate('/advertise');
          }}
        >
          Cancel
        </ButtonImage>
        <ButtonImage
          height={50}
          type="action"
          textTransform="unset"
          fontWeight={300}
          fontSize={14}
          onClick={setAds}
          disabled={!tableAdvertise?.length}
        >
          Set Ads
        </ButtonImage>
      </div>

      {modalSuccess && (
        <CompletedModal
          isOpenModal={modalSuccess}
          onOK={() => {
            setModalSuccess(false);
            setResetSelectedItem(false);
            fetchTable();
            getBalance();
          }}
          title="Set Ads Success!"
          message="Your advertisement has been successfully placed. Please check."
        />
      )}
    </NewAdvertiseWrapper>
  );
};

export default NewAdvertise;
