import { Input } from 'antd';
import styled from 'styled-components';

export const FormControlStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;

  label {
    text-transform: capitalize;
    display: block;
  }

  input,
  textarea {
    flex: ${({ flex }) => (flex ? 'unset' : '1')};
    outline: none;
    color: #fff;
    border: 1px solid #156df2;
    background: rgba(0, 0, 0, 0.21);
    padding: 16px;
    font-size: 14px;
    line-height: 150%; /* 21px */
    letter-spacing: 0.07px;
    resize: none;

    &:focus,
    &:active {
      outline: none;
    }
    &::placeholder {
      color: ${({ backgroundColor }) => (backgroundColor ? '#757B8C' : '#fff')};
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      margin: 1px 0;
      border-radius: 10px;
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.21);
    }
  }

  .ant-select {
    .ant-select-selector {
      border: 1px solid #156df2;
      background: rgba(0, 0, 0, 0.21);
      height: 55px;
      color: #fff;
      display: flex;
      align-items: center;

      &:after {
        color: #fff;
      }
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      color: red;
    }
  }

  .custom-thumbnail {
    .ant-upload-drag-container {
      width: 150px;
    }
  }

  .size-thumbnail {
    width: 150px;
  }

  .ant-upload-drag-container {
    border: 1px dashed #156df2;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;

    .upload-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    gap: 4px;
    label {
      font-size: 14px;
    }
  }
`;
