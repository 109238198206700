export const SYSTEM_ERROR = {
  NETWORK_ERROR: {
    STATUS: 'Network Error',
    MESSAGE: 'Request has been cancelled',
  },

  TIMEOUT_ERROR: {
    STATUS: 'Request Timeout',
    MESSAGE: 'The Request Has Timed Out',
  },
};

export const LOCAL_STORAGE_KEY = {
  LANGUAGE: 'language',
  TOKEN: 'token',
  REFRESH_TOKEN: 'refresh_token',
  THEME: 'theme',
  LOGIN_TYPE: 'login_type',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
};

export const styleVisibleModal = {
  top: "32%",
}

export const DAPP_WALLET_LIST = [
  {
    name: 'Metamask',
    link: 'https://metamask.app.link',
  },
  {
    name: 'Trust',
    link: 'https://link.trustwallet.com/open_url?coin_id=60&url=',
  },
];

export const MAX_FILE_SIZE = 1024 * 1024

export const FILE_OPTIONS = [
  {
    value: 'IMAGE',
    label: 'Image'
  },
  {
    value: 'VIDEO',
    label: 'Video'
  },
  {
    value: 'SLIDE',
    label: 'Slide'
  }
]

export const STATUS_OPTIONS = [
  {
    value: 'REQUESTED',
    label: 'Requested'
  },
  {
    value: 'APPROVED',
    label: 'Approved'
  },
  {
    value: 'REJECTED',
    label: 'Rejected'
  }
]

export const STATUS_NETWORK = {
  FULFILLED: 'fulfilled',
  PENDING: 'pending',
  REJECTED: 'rejected'
}

export const TYPE_FILE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  SLIDE: 'SLIDE'
}

export const TYPE_FILE_UPPERCASE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  SLIDE: 'SLIDE'
}

export const TAB_ADVERTISE = {
  INFO: "info",
  PLACE: "place"
}

export const REGEX_URL = /^(https?):\/\/([a-zA-Z0-9\/\-?&=$%+.#_:]+)$/i;

export const STATUS_ADS = {
  APPROVED: "APPROVED"
}

export const ADS_TYPE_OPTION =[
  {
    value: 'STANDARD',
    label: 'Standard',
    index: 1
  },
  {
    value: 'PREMIUM',
    label: 'Premium',
    index: 2
  },
  {
    value: 'PROFESSIONAL',
    label: 'Professional',
    index: 3
  }
]

export const FILE_TYPE = [
  'image/png',
  'image/PNG',
  'image/jpeg',
  'image/JPEG',
  'image/gif',
  'image/GIF',
  'video/mp4',
  'video/MP4',
  'video/mov',
  'video/MOV',
];

export const FILE_TYPE_VIDEO = [
  'video/mp4',
  'video/MP4',
  'video/mov',
  'video/MOV',
  'video/quicktime',
];

export const FILE_TYPE_IMAGE = [
  'image/png',
  'image/PNG',
  'image/jpeg',
  'image/JPEG',
  'image/gif',
  'image/GIF',
];

export const REGEX_PHONE = /^0\d{9,10}$|^0120\d{8}$/i;

export const FILE_SIZE = {
  IMAGE: '10',
  VIDEO: '20'
}