import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Select, DatePicker } from 'antd';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../../stores';
import { listRequestManagementAction } from '../../../../stores/screens/requestManagement/management.action';
import {
  getBuildingsAction,
} from '../../../../stores/screens/statistics/statistical.action';
import actions from '../../../../stores/screens/statistics/statistical.reducer';
import ButtonImage from '../../../../components/button-img';

import { Images } from '../../../../theme';
import { STATUS_ADS } from '../../../../constants';

import { Filter } from './styled';

const FilterComponent = () => {
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState({
    advertisementId: null,
    building: null,
    date: null
  });

  const { listRequestManagement } = useAppSelector(
    (state) => state.requestManagement
  );
  const { buildings, filter:filterReducer } = useAppSelector(
    (state) => state.statistics
  );

  useEffect(() => {
    setFilter({
      ...filter,
      date: moment(),
    });
    dispatch(actions.setFilter({ ...filter, date: moment().format('YYYY-MM-DD'), isChange: false }));
    return () => dispatch(actions.setFilter({
      advertisementId: null,
      building: null,
      date: null, }));
  }, []);

  useEffect(() => {
    dispatch(listRequestManagementAction({ status: STATUS_ADS.APPROVED }));
    dispatch(getBuildingsAction());
  }, []);

  const advertiseOptions = useMemo(() => {
    if (listRequestManagement?.items && !!listRequestManagement?.items.length) return listRequestManagement?.items.map((m) => ({ value: m.id, label: m.name, type: m?.type }))
    return [];
  }, [listRequestManagement?.items]);

  const buildingOptions = useMemo(() => {
    if (buildings && !!buildings.length) return buildings.map((m) => ({ value: m.id, label: m.name, }))
    return [];
  }, [buildings]);

  const onSearch = () => {
    setFilter({
      ...filter,
      date: moment(),
    });
    dispatch(actions.setFilter({ ...filter, isChange: !filterReducer.isChange, date: moment(filter.date || null).format('YYYY-MM-DD') }))
  };

  return (
    <Filter className='dp-flex'>
      <div className="form-group">
        <Select
          value={filter?.advertisementId}
          options={advertiseOptions}
          listHeight={230}
          className={`select`}
          placeholder="Advertisement"
          popupClassName="custom-option"
          onChange={(e) => setFilter({
            ...filter,
            advertisementId: e
          })}
          allowClear={true}
          suffixIcon={
            <img src={Images.ICON_DROPDOWN_WHITE} alt="icon-down" />
          }
          autoClearSearchValue={true}
        />
      </div>
      <div className="form-group">
        <Select
          value={filter?.building}
          options={buildingOptions}
          listHeight={230}
          className={`select`}
          placeholder="Building"
          popupClassName="custom-option"
          onChange={(e) => setFilter({
            ...filter,
            building: e
          })}
          allowClear={true}
          suffixIcon={
            <img src={Images.ICON_DROPDOWN_WHITE} alt="icon-down" />
          }
          autoClearSearchValue={true}
        />
      </div>
      <div className="form-group">
        <DatePicker
          value={filter.date}
          className={`date-picker`}
          popupClassName="date-picker-panel-custom"
          placeholder={'End date'}
          format={'YYYY-MM-DD'}
          showNow
          onChange={(e) => {
            setFilter((state) => ({
              ...state,
              date: e
            }));
          }}
          suffixIcon={<img src={Images.ICON_CALENDAR} alt="calendar" />}
          allowClear={false}          
        />
      </div>
      <ButtonImage
        onClick={onSearch}
        type="action"
        height={40}
        textTransform="unset"
        fontWeight={300}
        fontSize={14}
        className="btn-search"
      >
        Search
      </ButtonImage>
    </Filter>

  );
};

export default FilterComponent;
