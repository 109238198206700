import * as Yup from 'yup';

import { REGEX_PHONE, REGEX_URL } from '../../../constants';
const regexPhone = /^0\d{9,10}$|^0120\d{8}$/i;
export const schema =
  Yup.object().shape({
    companyName: Yup.string()
      .required('This field is required')
      .max(255, 'This field max length is 255')
      .trim(),
    URL: Yup.string()
      .required('This field is required')
      .matches(REGEX_URL, 'Enter a valid url')
      .max(255, 'This field max length is 255')
      // .test('', (value) => {})
      .trim(),
    title: Yup.string()
      .required('This field is required')
      .max(255, 'This field max length is 255')
      .trim(),
    introduction: Yup.string()
      .required('This field is required')
      .max(1000, 'This field max length is 1000')
      .trim(),
    phone: Yup.string()
      .required('This field is required')
      .transform((currentValue) => currentValue.replace(/-/g, ""))
      .trim()
      .matches(REGEX_PHONE, 'Phone number must be a valid number')
      // .max(12, 'This field max length is 12')
      .test("phone", "Phone number must be a valid number", (phone) => {
        if (!phone) {
          return true;
        } else {
          return regexPhone.test(phone);
        }
      }),
    type: Yup.string().required('This field is required'),
    files: Yup.mixed().required('This field is required'),
    aspect_ratio: Yup.string().required('This field is required'),
    thumbnail: Yup.mixed().when('type', {
      is: (val) => val && val.toLowerCase() === 'video',
      then: () => Yup.mixed().required('This field is required'),
      otherwise: () => Yup.mixed().nullable(),
    }),
  });
