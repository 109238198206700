import { DatePicker } from 'antd';
import 'antd/es/date-picker/style/css';
import styled from 'styled-components';

export const DatePickerStyled = styled(DatePicker)`
  width: 100%;
  max-width: 100%;
  height: 40px;
  background: transparent;

  &.ant-calendar-picker {
    width: 100%;
    max-width: 100%;
  }
`;
