import moment from "moment";
import React, { useEffect, useState } from "react";

import { useAppDispatch } from "../../stores";
import { generateVRCodeAction } from "../../stores/screens/generate-vr/generate.action";
import { Images } from "../../theme";
import LocalStorage from "../../utils/storage";
import ButtonImage from "../button-img";
import Toast from "../toast";
import { styleVisibleModal } from "../../constants";

import { ModalGenerateVRStyled } from "./styled";

const ModalGenerateVR = ({ accessToken, isOpen, onCancel = () => {} }) => {
  const timeAliveStorage = +LocalStorage.get("ExpiredTimeVRcode") || 0;
  const currentTime = moment.utc().unix();
  const counterCurrent = timeAliveStorage - currentTime;
  const [counter, setCounter] = useState(
    counterCurrent > 0 ? counterCurrent : 0
  );
  const [vrCode, setVrCode] = useState(
    counterCurrent > 0 ? LocalStorage.get("VRcode") : ""
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen && counter === 0) {
      generateCode();
    }
  }, [isOpen]);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const generateCode = () => {
    if (counter) return;
    getVRcode();
  };

  const getVRcode = async () => {
    try {
      dispatch(
        generateVRCodeAction({
          params: accessToken,
          callback: receiveResponseVRCode,
        })
      );
    } catch (error) {
      Toast.error(error?.error);
    }
  };

  const receiveResponseVRCode = (result) => {
    setVrCode(result?.data?.vrCode);
    setCounter(result?.data?.expiredAt - result?.data?.createAt);
    LocalStorage.set("ExpiredTimeVRcode", result?.data?.expiredAt);
    LocalStorage.set("VRcode", result?.data?.vrCode);
  };

  return (
    <ModalGenerateVRStyled
      onCancel={onCancel}
      open={isOpen}
      width={450}
      footer={null}
      destroyOnClose
      style={styleVisibleModal}
      closable={false}
    >
      <div className="title">Connect VR</div>
      <div className="sub-title">
        Enter the following code on your VR device to continue:
      </div>
      <div className="box-generate-vr">
        <div className="vr-code">{vrCode}</div>
      </div>
      <div className="action-box">
        <ButtonImage
          onClick={() => onCancel()}
          src={Images.CANCEL_BTN}
          height={50}
          className="btn-vr"
        >
          cancel
        </ButtonImage>
        <ButtonImage
          onClick={() => generateCode()}
          src={Images.GENERATE_BTN}
          height={50}
          className={`btn-vr ${counter && "disabled"}`}
        >
          <div className={`generate ${counter && "disabled"}`}>
            Generate Code&nbsp;<span>{counter ? `(${counter}s)` : ""}</span>
          </div>
        </ButtonImage>
      </div>
    </ModalGenerateVRStyled>
  );
};
export default ModalGenerateVR;
