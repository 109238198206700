import styled from 'styled-components';
export const ModalVerifyCodeStyled = styled.div`
  .title {
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
  }
  .sub-title {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 8px;
  }

  input {
    background-color: #fff;
    border-color: black;
    border-width: 1px;
    color: black;

    &::placeholder {
      color: #999999;
    }
  }
  .error {
    margin-top: 2px;
    color: red;
    text-align: center;
  }

  .action-box {
    margin: 20px 0px;
    display: flex;
    justify-content: center;

    .btn-submit {
      padding: 0px 10px;
      border-radius: 99999px;
      height: 50px;

      &.ant-btn:disabled {
        background: #fff;
      }

      @media (max-width: 575px) {
        img {
          width: 150px;
        }
      }
    }
  }

  .content-verify {
    text-align: center;
  }

  .box-generate-code {
    min-height: 140px;
    background: #0d174d;

    border-radius: 10px;
    @media (max-width: 575px) {
      margin-bottom: 20px;
    }
  }
`;
