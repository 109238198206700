import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import { useAppDispatch } from '../../stores';
import { Images } from '../../theme';
import ButtonImage from '../button-img';
import { ModalDeviceStyled } from './styled';
import { useAppSelector } from '../../stores';
import Toast from '../toast';
import LocalStorage from '../../utils/storage';
import { generateDeviceAction } from '../../stores/screens/generate-vr/generate.action';

const ModalDevice = ({ accessToken, isOpen, onCancel = () => {} }) => {
  const { userInfo } = useAppSelector((state) => state.auth);
  const timeAliveStorage = +LocalStorage.get('ExpiredTimeDeviceCode') || 0;
  const currentTime = moment.utc().unix();
  const counterCurrent = timeAliveStorage - currentTime;
  const [counter, setCounter] = useState(
    counterCurrent > 0 ? counterCurrent : 0
  );
  const [deviceCode, setDeviceCode] = useState(
    counterCurrent > 0 ? LocalStorage.get('DeviceCode') : ''
  );

  const dispatch = useAppDispatch();
  const startTimeRef = useRef(null);
  const endTimeRef = useRef(null);
  const rafRef = useRef(null);

  useEffect(() => {
    if (isOpen && counter === 0) {
      generateCode();
    }
  }, [isOpen]);

  // useEffect(() => {
  //   const timer =
  //     counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  //   return () => clearInterval(timer);
  // }, [counter]);

  useEffect(() => {
    if (counter > 0) {
      startTimeRef.current = Date.now();
      endTimeRef.current = startTimeRef.current + counter * 1000;
      rafRef.current = requestAnimationFrame(updateCounter);
    }

    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
  }, [counter]);

  const updateCounter = () => {
    const now = Date.now();
    const remaining = Math.max(0, Math.ceil((endTimeRef.current - now) / 1000));
    setCounter(remaining);

    if (remaining > 0) {
      rafRef.current = requestAnimationFrame(updateCounter);
    }
  };

  const generateCode = () => {
    if (counter) return;
    getDeviceCode();
  };

  const getDeviceCode = async () => {
    try {
      dispatch(
        generateDeviceAction({
          params: accessToken,
          callback: receiveResponseDeviceCode,
        })
      );
    } catch (error) {
      Toast.error(error?.error);
    }
  };

  const receiveResponseDeviceCode = (result) => {
    setDeviceCode(result?.data?.newCode);
    setCounter(result?.data?.expiredAt - result?.data?.createAt);
    LocalStorage.set('ExpiredTimeDeviceCode', result?.data?.expiredAt);
    LocalStorage.set('DeviceCode', result?.data?.newCode);
  };
  return (
    <ModalDeviceStyled
      onCancel={onCancel}
      open={isOpen}
      width={450}
      footer={null}
      destroyOnClose
      closable={false}
    >
      <div className="title">Connect Device</div>
      <div className="sub-title">
        The verification code has been sent to the email {userInfo?.email}
      </div>
      <div className="box-generate-device">
        <div className="device-code">{deviceCode}</div>
      </div>
      <div className="action-box">
        <ButtonImage
          onClick={onCancel}
          src={Images.CANCEL_BTN}
          height={50}
          className="btn-vr"
        >
          cancel
        </ButtonImage>
        <ButtonImage
          onClick={() => generateCode()}
          src={Images.GENERATE_BTN}
          height={50}
          className={`btn-vr ${counter && 'disabled'}`}
        >
          <div className={`generate ${counter && 'disabled'}`}>
            Generate Code&nbsp;<span>{counter ? `(${counter}s)` : ''}</span>
          </div>
        </ButtonImage>
      </div>
    </ModalDeviceStyled>
  );
};
export default ModalDevice;
