import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../stores';
import {
  getDetailAdvertiseAction,
  listBuildingAction,
  tableAdsBookingAction,
  tablePricingPlanAction,
} from '../../../stores/screens/advertise/advertise.action';
import AdvertiseTable from '../AdvertiseTable';
import AdvertiseFilter from '../Filter';
import PricingPlan from './../PricingPlan';
import { TabInfoWrapper } from './styled';
import { Images } from '../../../theme';
import BaseModal from './../../../components/base-modal';
import BaseTable from './../../../components/base-table';
import Misc from '../../../utils/misc';
import moment from 'moment/moment';
import FooterAdvertiseTable from '../AdvertiseTable/FooterAdvertiseTable';
import { STATUS_NETWORK } from '../../../constants';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getAspectRatiosAction } from '../../../stores/screens/requestManagement/management.action';

const TabInfo = ({ ...props }) => {
  const [paginate, setPaginate] = useState({});
  const [detailModal, setShowDetailModal] = useState(false);

  const dispatch = useAppDispatch();
  const { tableAdvertise, tablePricing, detailAdvertise, isLoading, tableAdvertiseLoading } = useAppSelector(
    (state) => state.advertise
  );

  useEffect(() => {
    dispatch(getAspectRatiosAction())
  }, []);

  useEffect(() => {
    const fetchTable = async () => {
      await Promise.all([
        dispatch(tablePricingPlanAction({})),
        dispatch(tableAdsBookingAction({})),
        dispatch(listBuildingAction({})),
      ]);
    };
    fetchTable();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = async () => {
    const res = await dispatch(tableAdsBookingAction(paginate));
    if (res?.meta?.requestStatus === STATUS_NETWORK.REJECTED) {
      message.open({
        type: 'error',
        content: res?.payload?.message,
      });
    }
  };

  const handleShowModalDetail = async (id, time_frame) => {
    const params = {
      id,
      timeFrame: time_frame,
      endDate: paginate?.endDate ?? null,
      startDate: paginate?.startDate ?? null,
    };
    await dispatch(getDetailAdvertiseAction(params));
    setShowDetailModal(true);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      render: (_, record, index) => <span>{index + 1}</span>,
    },
    {
      title: 'Advertiser',
      dataIndex: '',
      render: (_, record) => (
        <span style={{ display: 'inline-block', width: '300px' }}>
          {record?.advertiser?.email ??
            Misc.trimPublicAddress(record?.advertiser?.user_name, 4)}
        </span>
      ),
    },
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      render: (_, record) => (
        <span>{moment(record?.start_time).format('YYYY/MM/DD')}</span>
      ),
    },
    {
      title: 'End Time',
      dataIndex: 'end_time',
      render: (_, record) => (
        <span>{moment(record?.end_time).format('YYYY/MM/DD')}</span>
      ),
    },
  ];

  return (
    <TabInfoWrapper>
      <PricingPlan></PricingPlan>
      {tableAdvertiseLoading && (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
              }}
              spin
            />
          }
        />
      )}
      <div className="advertising">
        <h2 className="title">Advertising Information Sheet</h2>

        <div className="custom-frame">
          <div className="position-frame">
            <img src={Images.TABLE_FRAME_3} alt="" className="frame" />
            <img
              src={Images.LIGHT_DOT}
              alt=""
              className="light-dot light-dot-1"
            />
            <img
              src={Images.LIGHT_DOT}
              alt=""
              className="light-dot light-dot-2"
            />
            <img
              src={Images.LIGHT_DOT}
              alt=""
              className="light-dot light-dot-3"
            />
          </div>

          <div className="custom-frame-div">
            <AdvertiseFilter
              onSearch={onSearch}
              setPaginate={setPaginate}
              paginate={paginate}
            />

            <AdvertiseTable
              dataTable={tableAdvertise}
              tablePricing={tablePricing}
              handleShowModalDetail={handleShowModalDetail}
            />

            <FooterAdvertiseTable isCreate={false} />
          </div>
        </div>
      </div>

      <BaseModal
        title="Time Detail"
        open={detailModal}
        onCancel={() => setShowDetailModal(false)}
      >
        <div className="detail-modal">
          <BaseTable
            className="detail-table"
            columns={columns}
            data={detailAdvertise}
            pagination={false}
            loading={isLoading}
          ></BaseTable>
        </div>
      </BaseModal>
    </TabInfoWrapper>
  );
};

export default TabInfo;
