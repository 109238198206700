import { Button, Table } from 'antd';
import styled from 'styled-components';

export const AdvertiseTableWrapper = styled.div`
  .box-table {
    display: flex;
    // height: 466px;
    max-height: 466px;
    overflow: auto;
    // justify-content: center;
    border: 1px solid #156df280;
    &.h-337 {
      // height: 337px;
      max-height: 178px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #2b6ae680;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #2b6ae680;
    }

    &::-webkit-scrollbar-thumb:active {
      background: rgba(0, 0, 0, 0.9);
    }
  }

  .table-advertise {
    table-layout: auto;
    width: 100%;

    thead {
      height: 50px;
      // border-right: 1px solid #156df280;

      th {
        position: sticky;
        top: -1px;
        background-color: #051847;
        z-index: 10;
      }

      .t-header {
        border-bottom: 1px solid #156df280;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
      }

      .color-yellow {
        background: linear-gradient(yellow, yellow), white;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        padding: 5px;
        display: inline;
        font-size: 13px;

        text-shadow: 0px 0px 10px #fa8c16, 0px 0px 10px rgba(250, 140, 22, 0.5),
          0px 0px 10px #fa8c16;
      }
    }

    tbody {
      tr > td {
        // word-break: break-all;
        white-space: nowrap;
      }
      .center {
        width: 45px;
        height: 40px;
      }

      .frame-chosen {
        border: 1px solid #5fa9ff;
        width: 30px;
        height: 30px;
        margin: 0 auto;

        &.frame-used {
          background: #ffba43;
          border: 1px solid #ffe2af;
          box-shadow: 0px 0px 10px #ffba43, 0px 0px 10px rgba(255, 186, 67, 0.5),
            0px 0px 10px #ffba43;

          &:hover {
            background: #ffba43;
            border: 1px solid #ffe2af;
            box-shadow: 0px 0px 10px #ffba43,
              0px 0px 10px rgba(255, 186, 67, 0.5), 0px 0px 10px #ffba43;
          }
        }

        &.frame-not-used {
          background: #66db66;
          border: 1px solid #abffa9;
          box-shadow: 0px 0px 10px #66db66,
            0px 0px 10px rgba(102, 219, 102, 0.5), 0px 0px 10px #66db66;

          &:hover {
            background: #66db66;
            border: 1px solid #abffa9;
            box-shadow: 0px 0px 10px #66db66,
              0px 0px 10px rgba(102, 219, 102, 0.5), 0px 0px 10px #66db66;
          }
        }

        &.frame-selected {
          background: #f759ab;
          border: 1px solid #ffadd2;
          box-shadow: 0px 0px 10px #f759ab, 0px 0px 10px rgba(247, 89, 171, 0.5),
            0px 0px 10px #f759ab;

          &:hover {
            background: #f759ab;
            border: 1px solid #ffadd2;
            box-shadow: 0px 0px 10px #f759ab,
              0px 0px 10px rgba(247, 89, 171, 0.5), 0px 0px 10px #f759ab;
          }
        }

        &:hover {
          background: #156df2;
          border: 1px solid #b5d3ff;
          box-shadow: 0px 0px 10px #156df2, 0px 0px 10px rgba(21, 109, 242, 0.5),
            0px 0px 10px #156df2;
        }
      }
    }
  }

  .no-data {
    margin: 10px 0;
    width: 100%;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .ant-empty-description {
      color: #fff;
    }
  }
  .no-data-2 {
    margin: 10px 0;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .ant-empty-description {
      color: #fff;
    }
  }
`;

export const TooltipWrapper = styled.div`
  padding: 10px;

  .row {
    display: flex;
    justify-content: flex-end;

    .name {
      white-space: nowrap;
      margin-left: 10px;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .title {
      margin-right: auto;
    }

    .price {
      color: #fda000;
    }

    .sold {
      color: #f12222;
    }

    .available {
      color: #73d13d;
    }

    .type {
      text-transform: capitalize;
    }

    .btn-view {
      text-decoration: underline;
      cursor: pointer;
      color: #1e90ff;
    }
  }
`;

export const FooterAdvertiseWrapper = styled.div`
  //margin-top: auto;
  //padding-bottom: 20px;
  margin-top: 10px;

  .text-center {
    text-align: center;
  }
  .list-box {
    list-style-type: none;
    display: flex;
    gap: 20px;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 0px;

    li {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 100;
      font-size:14px;

      .box {
        width: 14px;
        height: 14px;
        border: 1px solid #5fa9ff;

        &.sold {
          background-color: #ffba43;
          border: 1px solid #ffe2af;
        }

        &.bought {
          background-color: #66db66;
          border: 1px solid #abffa9;
        }

        &.selected {
          background-color: #f759ab;
          border: 1px solid #ffadd2;
        }
      }
    }
  }

  .box-group {
    margin-top: 10px;
    margin-left: auto;
    display: flex;
    gap: 20px;

    .box-hour {
      border: 1px solid #156df2;
      background: #156df236;
      padding: 6px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 400;

      .hour,
      .amount {
        font-size: 18px;
        font-weight: 700;
        margin-left: 10px;
      }

      .amount {
        color: #fda000;
      }
    }
  }
`;

export const Total = styled.div`
  margin-top: 10px;
  display: flex;
  //justify-content: center;
  gap: 20px;

  .total-time {
    padding: 4px 16px;
    background: rgba(21, 109, 242, 0.21);
    border: 1px solid #156df2;
    display: flex;
    gap: 4px;
    align-items: center;
    height: 34px;

    .time-title {
      font-size: 14px;
      font-weight: 400;
    }
    .hour {
      font-size: 18px;
      font-weight: 700;
    }
  }

  .box-table {
    display: flex;
    // height: 466px;
    max-height: 180px;
    overflow: auto;
    // justify-content: center;
    //border: 1px solid #156df280;
    &.h-337 {
      // height: 337px;
      max-height: 178px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #2b6ae680;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #2b6ae680;
    }

    &::-webkit-scrollbar-thumb:active {
      background: rgba(0, 0, 0, 0.9);
    }
  }

  .total-amount {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .color-gray{
      color: #A8AEBA;
    }
    .amount-item {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;
      gap: 16px;

      .amount-title {
        font-size: 14px;
        font-weight: 400;
      }
      .amount-value {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .w-160{
      width: 160px;
    }
    .justify-space{
      justify-content: space-between;
    }
    .yellow {
      font-size: 18px;
      font-weight: 700;
      color: #fda000;
    }
  }

  .padding{
    padding: 4px 16px;
  }

  .border{
    border: 1px solid #156df2;
  }
  
  .height-auto{
    height: fit-content;
  }

  .gap-10{
    gap: 10px
  }

  .gap-5{
    gap: 5px
  }

  .detail-table {
    height: 150px;
    overflow: auto;
  }

  .w-600{
    width: 600px;
  }

  table.scroll {
    width: 100%;
    border-collapse: collapse;

    .ant-tooltip-open {
      z-index: 99999999 !important;
    }
    .first-col, .first-col ~ th {
      position: sticky;
      left: 1px;
    }

    thead {
      position: sticky;
      top: 0px;
      background-color: #051847;
      z-index: 9;
    }

    td, th {
      border: 1px solid #156DF2;
      padding: 3px 8px;
      text-align: left;
      font-weight: 400;
    }

    td {
      height: 30px;
      font-size: 14px;
    }

    thead {
      font-size: 14px;
      font-weight: 400;
    }

    thead::after {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      border-bottom: 0.5px solid #156DF2;
      border-top: 0.5px solid #156DF2;
      z-index: 9;
    }

    /* width */
    ::-webkit-scrollbar {
      width: 6px;
      height: 9px;
      position: absolute;
      z-index: 100;
      top:0;
      right: 0;
      display: block;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: unset;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #156df2;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #156df2;
    }
  }
`;

export const ButtonViewPrice = styled(Button)`
  margin: 0 0 0 auto;
  padding: 0;
  height: 25px;
  color: #FF0000;
  &[disabled] {
    color: #757B8C;
    &:hover{
     color: #757B8C;
    }
  }
  &:hover, &:focus{
     color: #FF0000;
  }
  span{
    text-decoration: underline;
  }
`;

export const DetailModalFooter = styled.div`
  .d-flex {
    display: flex;
  }

  .color-orange {
    font-weight: 700;
    color: #fda000;
  }

  .mg-left-auto {
    margin-left: auto;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .w-150 {
    width: 201px;
    padding: 0px 12px;
  }
  .w-191 {
    width: 191px;
    padding: 0px 12px;
  }
  .w-244 {
    width: 244px;
    padding: 0px 12px;
  }

  .text-center {
    text-align: center;
  }
  .color-white {
    color: #fff;
  }
`;
