import { Button, Input, Select, Spin, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import BaseTable from '../../components/base-table';
import BaseButton from '../../components/button';
import { STATUS_NETWORK, STATUS_OPTIONS, TYPE_FILE } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../stores';
import {
  deleteRequestAction,
  detailRequestAction,
  listRequestManagementAction,
  getAspectRatiosAction
} from '../../stores/screens/requestManagement/management.action';
import { Images } from '../../theme';
import ButtonImage from './../../components/button-img';
import CompletedModal from './CompletedModal';
import CreateModal from './CreateModal';
import SlideImages from './SlideImage';
import { ManagementContent, ModalPreview } from './styled';

import { LoadingOutlined } from '@ant-design/icons';
import BaseModal from '../../components/base-modal';
import DetailModal from './DetailModal';
import useVideoPlayer from '../../hooks/useVideoPlayer';

const ManagementPage = () => {
  const [isShowModal, setShowModal] = useState(false);
  const [isShowModalSuccess, setModalSuccess] = useState(false);
  const [paginate, setPaginate] = useState({
    query: null,
    status: null,
  });
  const [modalPreview, setModalPreview] = useState(false);
  const [contentType, setContentType] = useState(null);
  const [contentUrl, setContentUrl] = useState(null);
  const [contentArr, setContentArr] = useState([]);
  const [modalDelete, setModalDelete] = useState(false);
  const [idSelected, setIdSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [modalDeleteSuccess, setModalDeleteSuccess] = useState(false);
  const [nameFile, setNameFile] = useState('');
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const { listRequestManagement } = useAppSelector(
    (state) => state.requestManagement
  );

  const videoRef = useRef();
  const {
    playerState,
    togglePlay,
    skipBackward,
    skipForward,
    handleVideoProgress,
    toggleMute,
    toggleFullScreen,
    currentTime,
    totalTime,
    setPlayerState,
    handlePlayVideo
  } = useVideoPlayer(videoRef);

  useEffect(() => {
    dispatch(getAspectRatiosAction())
  }, []);
  
  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      render: (_, record) => <span>{record?.index + 1}</span>,
    },
    {
      title: 'Company',
      dataIndex: 'company_name',
      // key: 'company_name',
      render: (_, record) => (
        <div className="ellipsis">{record?.company_name}</div>
      ),
    },
    {
      title: 'URL',
      dataIndex: 'url',
      render: (_, record) => (
        <div className="ellipsis" style={{ color: '#2969FF' }}>
          <a href={record?.url} target="blank">
            {record?.url}
          </a>
        </div>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'name',
      render: (_, record) => <div className="ellipsis">{record?.name}</div>,
    },
    {
      title: 'Introduction',
      dataIndex: 'introduce',
      render: (_, record) => (
        <div className="ellipsis">{record?.introduce}</div>
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => (
        <span style={{ textTransform: 'capitalize' }}>
          {record?.type?.toLowerCase()}
        </span>
      ),
    },
    {
      title: 'Contain',
      dataIndex: 'content',
      render: (_, record) => {
        return (
          <Button
            type="link"
            onClick={() => previewFile(record)}
            style={{ color: '#2969FF' }}
          >
            Link {record?.type?.toLowerCase()}
          </Button>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, record) => (
        <div className={` status status__${record?.status?.toLowerCase()}`}>
          {record?.status?.toLowerCase()}
        </div>
      ),
    },
    {
      title: 'Message',
      dataIndex: 'message',
      render: (_, record) => <div className="ellipsis">{record?.message}</div>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <div className="dp-flex btn-group">
            <BaseButton
              className="btn-action detail"
              onClick={async () => {
                await dispatch(detailRequestAction(record.id));
                setIdSelected(record.id);
                setModalDetail(true);
              }}
            >
              <img src={Images.ICON_EYE} alt="icon-eye" />
            </BaseButton>
            <BaseButton
              className="btn-action delete"
              onClick={() => {
                setModalDelete(true);
                setIdSelected(record.id);
              }}
            >
              <img src={Images.ICON_TRASH} alt="icon-trash" />
            </BaseButton>
          </div>
        );
      },
    },
  ];

  const previewFile = (record) => {
    setModalPreview(true);
    setContentType(record?.type);
    setNameFile(record?.name);

    if (record?.type === TYPE_FILE.SLIDE) {
      setContentArr(record?.slides);
    } else {
      setContentUrl(record?.content);
    }
  };

  const fetchListRequest = async (pagination) => {
    const params = pagination
      ? {
          ...pagination,
          status: pagination?.status,
          query: pagination?.query?.trim(),
        }
      : {};
    setLoading(true);
    await dispatch(listRequestManagementAction(params));
    setLoading(false);
  };

  const handleDelete = async () => {
    try {
      setModalDelete(false);
      setLoading(true);
      const res = await dispatch(deleteRequestAction(idSelected));

      if (res?.meta?.requestStatus === STATUS_NETWORK.REJECTED) {
        message.open({
          type: 'error',
          content: res.payload.message,
        });
        return;
      }
      setModalDeleteSuccess(true);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchListRequest({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ManagementContent>
      {loading && (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
              }}
              spin
            />
          }
        />
      )}

      <div className="dp-flex justify-between title">
        <h2>Request Management</h2>

        <ButtonImage
          onClick={() => setShowModal(true)}
          type="action"
          height={50}
          textTransform="unset"
          fontWeight={300}
          fontSize={14}
        >
          New request
        </ButtonImage>
      </div>

      <div className="dp-flex title">
        <Input
          allowClear={true}
          className="input"
          placeholder="Search..."
          onChange={(e) => {
            setPaginate({ ...paginate, query: e.target.value });
          }}
          prefix={<img src={Images.ICON_SEARCH} alt="icon-search" />}
        ></Input>

        <Select
          options={STATUS_OPTIONS}
          className="select"
          placeholder="Status"
          popupClassName="custom-option"
          onChange={(e) => {
            setPaginate({ ...paginate, status: e });
          }}
          allowClear={true}
          suffixIcon={<img src={Images.ICON_DROPDOWN_WHITE} alt="icon-down" />}
        ></Select>

        <ButtonImage
          onClick={() => {
            fetchListRequest(paginate);
            setCurrentPage(1);
          }}
          type="action"
          height={40}
          textTransform="unset"
          fontWeight={300}
          fontSize={14}
        >
          Search
        </ButtonImage>
      </div>

      <BaseTable
        data={listRequestManagement.items}
        columns={columns}
        pagination={{ current: currentPage }}
        onChange={(pagination) => {
          setCurrentPage(pagination.current);
        }}
      />

      {isShowModal && (
        <CreateModal
          isOpenModal={isShowModal}
          title="New Request"
          onCancel={() => {
            setShowModal(false);
          }}
          setLoading={setLoading}
          setModalSuccess={setModalSuccess}
        />
      )}

      {isShowModalSuccess && (
        <CompletedModal
          isOpenModal={isShowModalSuccess}
          onOK={() => {
            console.log('=====> OK');
            setModalSuccess(false);
            fetchListRequest(paginate);
          }}
          title="Add Success!"
          message="New request has been successfully created."
        />
      )}

      {modalDeleteSuccess && (
        <CompletedModal
          isOpenModal={modalDeleteSuccess}
          onOK={() => {
            setModalDeleteSuccess(false);
            setModalDetail(false);
            fetchListRequest(paginate);
          }}
          title="Delete Success!"
          message="You have successfully deleted the advertisement."
        />
      )}

      {modalPreview && (
        <ModalPreview
          open={modalPreview}
          onCancel={() => {
            setModalPreview(false);
            setContentType(null);
            setContentUrl(null);
            setContentArr([]);
            setPlayerState({});
          }}
          footer={null}
          centered
          closeIcon={<img src={Images.ICON_CLOSE_2} alt="close-icon" />}
          width={contentType === TYPE_FILE.SLIDE ? 1000 : 'auto'}
        >
          {contentType === TYPE_FILE.IMAGE && (
            <img
              className='preview'
              src={contentUrl}
              alt="Preview"
              style={{ maxWidth: '1000px', maxHeight: '80vh' }}
            />
          )}
          {contentType === TYPE_FILE.VIDEO && (
            <div className="video-content">
              <video
                disablePictureInPicture
                style={{ maxWidth: '1000px', maxHeight: '80vh' }}
                ref={videoRef}
                onPlay={handlePlayVideo}
                onPause={handlePlayVideo}
              >
                <source src={contentUrl} type="video/mp4" />
              </video>
              <div className="controls">
                <div className="name-video" style={{ width: 'calc(100% - 170px)' }}>{nameFile}</div>
                <div style={{ position: 'absolute', top: '5px', right: '10px', display: "flex", color: "#c7c5c5", fontSize: "16px", gap: '5px'}}>
                  <div >{currentTime}</div>
                  <p>/</p>
                  <div>{totalTime}</div>
                </div>
                <input
                  className="range"
                  type="range"
                  min="0"
                  max="100"
                  value={playerState.progress ?? 0}
                  onChange={(e) => handleVideoProgress(e)}
                />
                <button className="btn-control btn-play" onClick={togglePlay}>
                  {playerState.isPlaying ? (
                    <img src={Images.ICON_PAUSE_VID} alt="" />
                  ) : (
                    <img src={Images.ICON_PLAY_VID} alt="" />
                  )}
                </button>

                <button className="btn-control btn-prev" onClick={skipBackward}>
                  <img src={Images.ICON_PREV} alt="" />
                </button>
                <button className="btn-control btn-next" onClick={skipForward}>
                  <img src={Images.ICON_NEXT} alt="" />
                </button>

                <button className="btn-control btn-mute" onClick={toggleMute}>
                  {playerState.isMuted ? (
                    <img src={Images.ICON_MUTE} alt="" />
                  ) : (
                    <img src={Images.ICON_UN_MUTE} alt="" />
                  )}
                </button>
                <button
                  className="btn-control btn-full"
                  onClick={toggleFullScreen}
                >
                  <img src={Images.ICON_SHOW_FULL_SCREEN} alt="" />
                </button>
              </div>
            </div>
          )}
          {contentType === TYPE_FILE.SLIDE && (
            <SlideImages images={contentArr}></SlideImages>
          )}
        </ModalPreview>
      )}

      {modalDelete && (
        <BaseModal
          open={modalDelete}
          onCancel={() => {
            setModalDelete(false);
          }}
          smallModal={true}
        >
          <div className="modal-delete">
            <h2>Delete advertisement</h2>
            <div>Do you really want to delete this advertisement?</div>
            <div className="btn-group">
              <ButtonImage
                type="cancel"
                onClick={() => {
                  setModalDelete(false);
                }}
                height={40}
                textTransform="unset"
                fontWeight={300}
                fontSize={14}
              >
                Cancel
              </ButtonImage>
              <ButtonImage
                type="action"
                onClick={handleDelete}
                height={40}
                textTransform="unset"
                fontWeight={300}
                fontSize={14}
              >
                Delete
              </ButtonImage>
            </div>
          </div>
        </BaseModal>
      )}

      {modalDetail && (
        <DetailModal
          open={modalDetail}
          footer={null}
          centered
          onCancel={() => {
            setModalDetail(false);
          }}
          id={idSelected}
          setModalDetail={setModalDetail}
          title="Detail Ads"
          onDelete={() => {
            setModalDelete(true);
          }}
        />
      )}
    </ManagementContent>
  );
};

export default ManagementPage;
