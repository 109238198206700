import axios from 'axios';
import { SYSTEM_ERROR, LOCAL_STORAGE_KEY } from '../../constants';
import { removeLocalLogout } from '../../utils/auth';
import Confirmable from '../../components/confirmable';
import MaskLoading from '../../components/mask-loading';

export class AxiosClient {
  token = localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN) ?? '';
  constructor() {
    this.instance = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      headers: {
        Authorization: this.getToken(),
        TimeZone: this.getTimeZone()
      },
      timeout: 10000,
      timeoutErrorMessage: SYSTEM_ERROR.TIMEOUT_ERROR.MESSAGE,
    });

    this._initializeResponseInterceptor();
  }

  getToken() {
    return `Bearer ${this.token}`;
  }
  getTimeZone() {
    const timeZoneOffset = new Date().getTimezoneOffset();
    return (timeZoneOffset / 60) * (-1)
  }
  setToken(token) {
    this.token = token;
  }

  _initializeResponseInterceptor = () => {
    this.instance.interceptors.request.use(this._handleRequestSuccess, this._handleRequestError);
    this.instance.interceptors.response.use(this._handleResponseSuccess, this._handleResponseError);
  };

  _handleRequestSuccess = (config) => {
    return config;
  };

  _handleRequestError = (error) => {
    // eslint-disable-next-line
    console.error(`[request error] [${JSON.stringify(error)}]`);
    if (error.response) {
      return error?.response?.data;
    }

    return Promise.reject(error);
  };

  _handleResponseSuccess = ({ data }) => data;

  _handleResponseError = async (error) => {
    if (error.code && error.code === 'ERR_NETWORK') {
      /*
        code: "ERR_NETWORK"
        message: "Network Error"
        name: "AxiosError"
      */
      // const { code } = error;
      return await Promise.reject(new Error("Failed to fetch"));
    }
    if (error.code && error?.response?.data?.error === 'ERR_USER_BLOCKED') {
      MaskLoading.close();
      removeLocalLogout();
      await Confirmable.open({
        content: 'Your account has been blocked!',
        hideCancelButton: true,
      });
      window.location = '/login';
    } else if (
      error.code &&
      (error?.response?.data?.error === 'ERROR_JWT_TOKEN_EXPIRED' ||
        error?.response?.data?.error === 'JWT_ERROR_REGEX')
    ) {
      MaskLoading.close();
      removeLocalLogout();
      window.location = '/login';
    }

    return await Promise.reject({
      httpStatus: error.response?.status,
      ...(error?.response?.data || {}),
    });
  };

  async request(config) {
    return await this.instance.request(config);
  }

  async get(url, config) {
    return await this.instance.get(url, config);
  }

  async delete(url, config) {
    return await this.instance.delete(url, config);
  }

  async post(url, data, config) {
    return await this.instance.post(url, data, config);
  }

  async put(url, data, config) {
    return await this.instance.put(url, data, config);
  }

  async patch(url, data, config) {
    return await this.instance.patch(url, data, config);
  }
}
