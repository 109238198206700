import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { UserOutlined } from '@ant-design/icons';
import { useAppDispatch } from '../../stores';
import Storage from '../../utils/storage';

import Misc from '../../utils/misc';
import useSSOLogin from '../../hooks/ssoLogin';
import { useWeb3Auth } from '../../contexts/web3auth';
import { CHAIN_LIST } from '../../constants/chains';
import * as api from '../../api';
import {
  refreshTokenAction,
  retrieveAccountAction,
} from '../../stores/screens/auth/auth.action';
import Toast from '../../components/toast';

import ButtonImage from '../../components/button-img';

/** asset */
import { Images } from '../../theme';
import { HeaderStyled } from './styled';

function Header({ accessToken, publicAddress, userInfo }) {
  const navigate = useNavigate();
  const { handleLogout } = useWeb3Auth();
  const { auth_token, public_address } = useSSOLogin();
  const { pathname, search } = useLocation();
  const dispatch = useAppDispatch();
  const isLogin = !!Storage.get('ACCESS_TOKEN');

  const handleLogin = () => {
    search?.includes('source')
      ? navigate(`/login${search}`)
      : navigate('/login');
  };

  const handleSubmitCrossSite = async ({ accounts, identifier }) => {
    try {
      const { code, data } = await api.loginSSOWithWallet({
        auth_token: accounts,
        identifier: identifier,
      });
      const { access_token, user_id, expired_at, refresh_token } = data;
      if (code === 200) {
        // window.open(
        //   `http://localhost:3001/?authToken=${access_token}&userId=${user_id
        //   }&publicAddress=${identifier}&expiredToken=${expired_at}&refreshToken=${refresh_token}`,
        //   '_blank'
        // );
        window.open(
          `${process.env.REACT_APP_SITE_WALLET_URL}?authToken=${access_token}&userId=${user_id}&publicAddress=${identifier}&expiredToken=${expired_at}&refreshToken=${refresh_token}`,
          '_blank'
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        try {
          const { payload } = await dispatch(
            refreshTokenAction({
              auth_token: accounts,
              identifier: identifier,
            })
          );
          if (payload?.data && payload.data?.access_token) {
            return handleSubmitCrossSite({
              accounts: payload?.data?.access_token,
              identifier,
            });
          }
        } catch (error) {
          if (error?.response?.status === 401) {
            handleLogout();
            navigate('/login');
          } else {
            Toast.error(error?.message);
          }
        }
      } else {
        Toast.error(error?.message);
      }
    }
  };

  const handleSSO = async () => {
    if (isLogin) {
      const { payload } = await dispatch(
        retrieveAccountAction(accessToken || auth_token)
      );

      if (payload?.status) {
        handleSubmitCrossSite({
          accounts: payload?.data.accounts[0].auth_token,
          identifier: payload?.data.accounts[0].identifier,
        });
      }
    } else {
      window.open(process.env.REACT_APP_SITE_WALLET_URL, '_blank');
    }
  };
  return (
    <HeaderStyled data-aos="fade-down">
      <div
        onClick={() => {
          const hasSourceOrReceiveMsg =
            search?.includes('source') || search?.includes('receiveMsg');

          if (!hasSourceOrReceiveMsg) {
            navigate('/');
          }
        }}
        className="logo"
      >
        <img src={Images.LOGO_HEADER} alt="Logo MCC" />
      </div>
      {!pathname.includes('/login') && (
        <div className="actions">
          {!search?.includes('source') && (
            <ButtonImage style={{ height: 60 }} onClick={handleSSO}>
              <span style={{ fontSize: 14 }}>
                {!accessToken && !auth_token
                  ? 'Connect to Wallet'
                  : 'My Wallet'}
              </span>
            </ButtonImage>
          )}

          {!accessToken && !auth_token ? (
            <ButtonImage onClick={handleLogin} type="login" />
          ) : (
            <>
              <div className="address-user">
                {Misc.trimPublicAddress(publicAddress || public_address, 4)}
                <div className="logo-user">
                  {userInfo?.avatar_url ? (
                    <img
                      width={48}
                      height={48}
                      src={userInfo?.avatar_url}
                      alt="logo-user"
                    />
                  ) : (
                    <div className="logo-default">
                      <UserOutlined className="icon-user" />
                    </div>
                  )}

                  <div className="avatar-dropdown">
                    <div className="bg-1">
                      <div className="bg-2">
                        <div className="bg-3">
                          <div className="content-dropdown">
                            <div className="public-address">
                              <a
                                href={`${
                                  CHAIN_LIST[1].blockExplorerUrls
                                }address/${publicAddress || public_address}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {Misc.trimPublicAddress(
                                  publicAddress || public_address,
                                  6
                                )}
                              </a>
                            </div>
                            <div className="divider"></div>
                            <div
                              className="logout"
                              onClick={() => {
                                navigate('/management');
                              }}
                            >
                              <img
                                src={Images.ICON_SETTING}
                                alt="icon-setting"
                              />
                              Advertise
                            </div>
                            <div className="logout" onClick={handleLogout}>
                              <img src={Images.ICON_LOGOUT} alt="icon-logout" />
                              Log Out
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </HeaderStyled>
  );
}

export default Header;
