import React, { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../../stores";
import { feeAmount, fixAdditionErrorJavascript, fixAmount, oneDecimalPlace, sliceDecimalTwo } from "../../../utils/number";
import { FooterAdvertiseWrapper, Total } from "./styled";
import { Tooltip } from "antd";
import BigNumber from "bignumber.js";

const FooterAdvertiseTable = ({
  isCreate,
  totalTime,
  totalAmount,
  daySelected,
  feePercent,
  paramsSearch,
  timeFrame,
  discountPercent,
}) => {
  const { listBuilding } = useAppSelector((state) => state.advertise);
  const { listRequestManagement } = useAppSelector(
    (state) => state.requestManagement
  );
  const [dataTable, setDataTable] = useState([]);

  const amount = useMemo(
    () => fixAmount(totalAmount) * daySelected,
    [daySelected, totalAmount]
  );

  const discount = feeAmount(amount, discountPercent);

  const fee = useMemo(() => {
    if (feePercent) return feeAmount(amount - discount, feePercent)
    return 0;
  }, [amount, feePercent, discount]);

  const buildingName = dataTable
    .map((item) => item.building)
    .filter((value, index, self) => self.indexOf(value) === index)
    .join(", ");

  const advertiseName =
    listRequestManagement.items &&
    listRequestManagement.items.find(
      (item) => item.id === paramsSearch?.advertisementId
    );

  useEffect(() => {
    const createTableData = () => {
      if (!timeFrame || timeFrame.length === 0 || !timeFrame[0]?.plan_prices) {
        return [];
      }

      const planPrices = timeFrame.reduce((acc, curr) => {
        const prices = Array.isArray(curr.plan_prices)
          ? curr.plan_prices
          : [curr.plan_prices];
        const extendedPrices = prices.map((price) => ({
          hour: price.hour,
          price: price.price,
          building: curr?.building,
          frame: curr?.frame,
        }));
        return [...acc, ...extendedPrices];
      }, []);

      const tableData = [];
      const dayData = planPrices
        .map((item) => {
          const nextHour = (Number(item.hour) + 1) % 24;
          return {
            time_slot: `${String(item.hour).padStart(2, "0")}-${String(
              nextHour
            ).padStart(2, "0")}`,
            quantity: daySelected,
            price: sliceDecimalTwo(item.price),
            amount: sliceDecimalTwo(daySelected * item.price),
            building: item?.building,
            frame: item?.frame,
          };
        });
        //sort by priority: same building > same frame > time small to large
        dayData.sort((a, b) => {
          if (a.building !== b.building) {
              return a.building.localeCompare(b.building);
          } else if (a.frame !== b.frame) {
              return a.frame.localeCompare(b.frame);
          } else {
              const aTime = a.time_slot.split('-').map(Number);
              const bTime = b.time_slot.split('-').map(Number);
              if (aTime[0] !== bTime[0]) {
                  return aTime[0] - bTime[0];
              }
              return aTime[1] - bTime[1];
          }
        });
      tableData.push(...dayData);
      return tableData;
    };

    const data = createTableData();
    setDataTable(data);
  }, [daySelected, timeFrame]);

  const totalAfterDiscount = useMemo(() => {
    if (totalAmount) return sliceDecimalTwo(BigNumber(BigNumber(totalAmount).times(daySelected)).minus(discount));
    return 0;
  }, [totalAmount, daySelected, discount]);

  return (
    <FooterAdvertiseWrapper>
      {isCreate && (
        <>
          <div className="text-center">Confirmation</div>
          <Total>
            <div>
              <div className="total-amount border padding height-auto gap-10 w-600">
                <div className="amount-item">
                  <span className="amount-title color-gray w-160">
                    Building :
                  </span>
                  <Tooltip
                    placement="top"
                    color="#051847"
                    overlayInnerStyle={{
                      border: "1px solid #156DF280",
                      borderRadius: "10px",
                      width: "fit-content",
                    }}
                    title={buildingName}
                    autoAdjustOverflow={true}
                    destroyTooltipOnHide={true}
                    zIndex={999}
                  >
                    <span
                      className="amount-value split-name"
                      style={{
                        maxWidth: "380px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {buildingName}
                    </span>
                  </Tooltip>
                </div>
                <div className="amount-item">
                  <span className="amount-title color-gray w-160">
                    Advertisement :
                  </span>
                  <span
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: '360px',
                    }}
                    className="amount-value split-name"
                  >
                    {advertiseName?.name}
                  </span>
                </div>
                <div className="amount-item">
                  <span className="amount-title color-gray w-160">
                    Advertisement Type :
                  </span>
                  <span className="amount-value">{paramsSearch?.type}</span>
                </div>
                <div className="amount-item">
                  <span className="amount-title color-gray w-160">
                    Start date :
                  </span>
                  <span className="amount-value">
                    {paramsSearch?.startDate}
                  </span>
                </div>
                <div className="amount-item">
                  <span className="amount-title color-gray w-160">
                    End date :
                  </span>
                  <span className="amount-value">{paramsSearch?.endDate}</span>
                </div>
                <div className="amount-item">
                  <span className="amount-title color-gray w-160">Days :</span>
                  <span className="amount-value">
                    {paramsSearch?.startDate && paramsSearch?.endDate
                      ? daySelected
                      : ""}
                  </span>
                </div>
              </div>
              <div
                className="dp-flex"
                style={{
                  justifyContent: "space-between",
                  height: "calc(100% - 192px)",
                  alignItems: "end",
                }}
              >
                <ul className="list-box">
                  <li>
                    <div className="box"></div> <span>Available</span>
                  </li>
                  <li>
                    <div className="box sold"></div> <span>Sold out</span>
                  </li>
                  {isCreate && (
                    <li>
                      <div className="box selected"></div> <span>Selected</span>
                    </li>
                  )}
                  <li>
                    <div className="box bought"></div>{" "}
                    <span>Bought by yourself</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="total-amount gap-5">
              <div
                className="total-amount box-table"
                style={{ maxHeight: "121px", overflow: "auto" }}
              >
                <table className="scroll">
                  <thead>
                    <tr>
                      <th className="color-gray first-col" style={{ width: '200px' }}>Building</th>
                      <th className="color-gray first-col">Frame</th>
                      <th className="color-gray first-col">Time Slot</th>
                      <th className="color-gray first-col">Quantity</th>
                      <th className="color-gray first-col" style={{ width: '150px' }}>Price (MCC Point)</th>
                      <th className="color-gray first-col" style={{ width: '150px' }}>Amount (MCC Point)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataTable.length > 0 ? (
                      <>
                        {dataTable.map((item, index) => {
                          return (
                            <tr key={index}>
                              <Tooltip
                                placement="top"
                                color="#051847"
                                overlayInnerStyle={{
                                  border: '1px solid #156DF280',
                                  borderRadius: '10px',
                                  width: 'fit-content',
                                }}
                                title={item.building}
                                autoAdjustOverflow={true}
                                destroyTooltipOnHide={true}
                                zIndex={999}
                              >
                                <td
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    maxWidth: '100px'
                                  }}
                                >
                                  {item.building}
                                </td>
                              </Tooltip>
                              <td>
                                {item.frame.charAt(0).toUpperCase() +
                                  item.frame.slice(1).toLowerCase()}
                              </td>
                              <td>{item.time_slot}</td>
                              <td>{item.quantity}</td>
                              <td>{item.price}</td>
                              <td>{item.amount}</td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <tr></tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className="total-amount border padding gap-5"
                style={{ marginTop: "10px", justifyContent: 'center' }}
              >
                <div className="amount-item justify-space">
                  <span className="amount-title ">
                    Total amount (before discount) :
                  </span>
                  <span className="amount-value">{`${oneDecimalPlace(
                    totalAmount * daySelected
                  )} MCC Point`}</span>
                </div>
                <div className="amount-item justify-space">
                  <span className="amount-title ">
                    Discount ({discountPercent}%) :
                  </span>
                  <span className="amount-value">{`${oneDecimalPlace(
                    discount
                  )} MCC Point`}</span>
                </div>
                <div className="amount-item justify-space">
                  <span className="amount-title ">
                    Total amount (after discount ) :
                  </span>
                  <span className="amount-value">
                    {`${oneDecimalPlace(
                      BigNumber(BigNumber(totalAmount).times(daySelected)).minus(discount)
                    )} MCC Point`}
                  </span>
                </div>
                <div className="amount-item justify-space">
                  <span className="amount-title ">Transaction fee ({feePercent}%) :</span>
                  <span className="amount-value">{`${oneDecimalPlace(
                    fee
                  )} MCC Point `}</span>
                </div>
                <div className="amount-item justify-space">
                  <span className="yellow ">Total due :</span>
                  <span className="yellow">{`${oneDecimalPlace(BigNumber(totalAfterDiscount).plus(fee))} MCC Point`}</span>
                </div>
              </div>

            </div>
          </Total>
        </>
      )}
    </FooterAdvertiseWrapper>
  );
};

export default FooterAdvertiseTable;
